import {Deployment} from "kubernetes-types/apps/v1";
import {Elements, Service} from "../flow";
import {getIncomers, getOutgoers, isNode, Node} from "react-flow-renderer";

interface List {
  apiVersion: 'v1',
  kind: 'List',
  items: Array<any>
}

const layoutName = "devel";

export function genAll(elements: Elements): string {
  const nodes: Array<Node<Service>> = elements.filter( (e) => isNode(e) ) as Array<Node<Service>>;
  const deployments = nodes.map((node) => genDeployment(node, getIncomers(node, elements), getOutgoers(node, elements)));
  const list: List = {
    apiVersion: 'v1',
    kind: 'List',
    items: deployments
  }
  return JSON.stringify(list);
}

export function genDeployment(node: Node<Service>, inputs: Array<Node<Service>>, outputs: Array<Node<Service>>): Deployment {
  return {
    apiVersion: 'apps/v1',
    kind: 'Deployment',
    metadata: {
      name: `${node.data?.label.toLowerCase()}-${node.id}`,
      labels: { openflowxlayout: layoutName },
      annotations: {
        'app.openshift.io/connects-to': JSON.stringify(outputs.map( (n) => {
          return {
            apiVersion: 'apps/v1',
            kind: 'Deployment',
            name: `${n.data?.label.toLowerCase()}-${n.id}`
          }
        }))
      }
    },
    spec: {
      replicas: 1,
      revisionHistoryLimit: 1,
      selector: { matchLabels: {
          app: node.id,
          openflowxlayout: layoutName
      } },
      template: {
        metadata: {
          labels: {
            app: node.id,
            openflowxlayout: layoutName
          }
        },
        spec: {
          containers: [
            {
              image: 'codycode/open_flow_x_worker',
              name: node.id,
              env: [
                { name: 'OPENFLOWXLAYOUT', value: layoutName },
                { name: 'OPENFLOWXNODEID', value: node.id },
                { name: 'OPENFLOWXNODENAME', value: node.data?.label },
                { name: 'OPENFLOWXNODETYPE', value: node.type },
                { name: 'OPENFLOWXCODE', value: node.data?.code },
                { name: 'OPENFLOWXSETUP', value: node.data?.setup },
                { name: 'OPENFLOWXINPUTS', value: JSON.stringify(inputs.map((n) => n.id))},
                { name: 'MQTT_BROKER', value: "mqtt://rabbitmq:1883"}
              ]
            }
          ]
        }
      }
    }
  }
}
