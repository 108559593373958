import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import style from './style.module.scss';

interface SidebarProps {
  code?: string;
  setup?: string;
  title?: string;
  onCodeChange: (code:string) => void;
  onSetupChange: (setup:string) => void;
  onTitleChange: (title:string) => void;
  output1?: string;
  output2?: string;
}

function Sidebar({code, setup, title, onCodeChange, onSetupChange, onTitleChange, output1, output2}: SidebarProps) {
  const disabled = code === undefined || title === undefined;
  if ( disabled ) {
    code = ''
    title = 'nothing selected';
  }
  return (
    <div className={style.sidebar}>
      <div className={style.input}>
        <label>Node Name</label>
        <input type="text" value={title} disabled={disabled} onChange={(e) => onTitleChange(e.target.value)} />
      </div>
      <div>async function handler(msg, topic, ofx_layout, ofx_node_id, ofx_node_name, ofx_node_type, ofx_inputs) &#123;
        <span className={style.comment}>&nbsp;#Javascript</span>
      </div>
      <textarea value={code} disabled={disabled} onChange={(e) => onCodeChange(e.target.value)}></textarea>
      <div>&#125;</div>
      <div>function setup(ofx_layout, ofx_node_id, ofx_node_name, ofx_node_type, ofx_inputs) &#123;
        <span className={style.comment}>&nbsp;#Javascript</span>
      </div>
      <textarea value={setup} disabled={disabled} onChange={(e) => onSetupChange(e.target.value)}></textarea>
      <div>&#125;</div>
      <div className={style.output}>
        <h1>Elements</h1>
        <CopyToClipboard text={output1 || ''}>
          <button>Copy</button>
        </CopyToClipboard>
        <div className={style.code}>
          {output1}
        </div>
      </div>
      <div className={style.output}>
        <h1>Deployments</h1>
        <CopyToClipboard text={output2 || ''}>
          <button>Copy</button>
        </CopyToClipboard>
        <div className={style.code}>
          {output2}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
