import React from 'react';

export type NodeType = 'input' | 'output' | 'default';

export type PaletteNode = { name: string, type?: NodeType, code?: string, setup?: string };

interface TagProps {
  children: string;
  type?: NodeType;
  code?: string;
  setup?: string;
}

export interface PaletteProps {
  things: PaletteNode[]
}

function Tag({children, type = 'default', code = 'return msg;', setup = ''}: TagProps) {
  const onDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData('application/reactflow-type', type as string);
    event.dataTransfer.setData('application/reactflow-label', children as string);
    event.dataTransfer.setData('application/reactflow-code', code as string);
    event.dataTransfer.setData('application/reactflow-setup', setup as string);
    event.dataTransfer.effectAllowed = 'move';
  }
  return (
    <div onDragStart={onDragStart} draggable style={{border: '1px solid black', margin: '8px', padding: '8px'}}>
      {children} &lt;{type}&gt;
    </div>
  )
}

function Palette({things}: PaletteProps) {
  const tags = things.map(({name, type, code, setup}, id) => (<Tag type={type} key={id} code={code} setup={setup}>{name}</Tag>));
  return (
    <div>
      Palette
      {tags}
    </div>
  )
}

export default Palette;
