import React, {useRef, ReactElement} from 'react';
import {v4 as uuid} from 'uuid';
import ReactFlow, {
  addEdge,
  removeElements,
  Node,
  Edge,
  Connection,
  isNode
} from 'react-flow-renderer';

import style from './style.module.scss';

export interface Service {
  label: string;
  code: string;
  setup: string;
}

export interface Pipe {
}

export type Element = Node<Service> | Edge<Pipe>
export type Elements = Array<Element>;

interface FlowProps {
  children: string | ReactElement | ReactElement[];
  updateElements: (callback: (elements: Elements) => Elements) => void;
  onSelect: (element: Node<Service>) => void;
  elements?: Elements;
}

function Index({children, updateElements, onSelect, elements = []}: FlowProps) {
  const reactFlowWrapper = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
  const onConnect = (newEdge: Edge<any> | Connection) => updateElements((es) => addEdge(newEdge, es));
  const onRemove = (toRemove: Elements) => updateElements((es) => removeElements(toRemove, es));
  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }
  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const position = {x: 0, y: 0};
    if (reactFlowWrapper.current) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      position.x = event.clientX - reactFlowBounds.left;
      position.y = event.clientY - reactFlowBounds.top;
    }
    const name = event.dataTransfer.getData('application/reactflow-label')
    const newNode: Node<Service> = {
      id: uuid(),
      type: event.dataTransfer.getData('application/reactflow-type'),
      position,
      data: {
        label: name,
        code: event.dataTransfer.getData('application/reactflow-code'),
        setup: event.dataTransfer.getData('application/reactflow-setup')
      }
    }
    updateElements((es) => es.concat(newNode));
    onSelect(newNode);
  }
  const onElementClick = (event: React.MouseEvent, element: Element) => {
    if (isNode(element)) {
      onSelect(element as Node<Service>);
    }
  }
  return (
    <div className={style.flow} ref={reactFlowWrapper}>
      <ReactFlow
        elements={elements}
        onConnect={onConnect}
        onElementsRemove={onRemove}
        snapToGrid={true}
        snapGrid={[15, 15]}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onElementClick={onElementClick}
      >
        {children}
      </ReactFlow>
    </div>
  );
}

export default Index;
