import React, {useState} from 'react';
import {Controls, ReactFlowProvider, Node} from "react-flow-renderer";

import Palette, {PaletteNode} from './palette';
import Sidebar from './sidebar';
import Flow, {Elements, Service} from './flow';
import {genAll} from "./generator";

const things: PaletteNode[] = [
  {name: 'MQTT-Test-Emitter', type: 'input', setup: "setInterval(() => {\n" +
      "  if ( global.client.connected ) {\n" +
      "    global.handler({}, ofx_node_id).then( (msg) => {\n" +
      "      global.client.publish(ofx_node_id, JSON.stringify(msg));\n" +
      "    });\n" +
      "  }\n" +
      "}, 1000);", code: 'msg.nodes = [ofx_node_name];\nreturn msg;'},
  {name: 'MQTT-Test-Adapter', code: "msg.nodes.push(ofx_node_name);\nreturn msg;"},
  {name: 'MQTT-Test-Consumer', type: 'output', code: "msg.nodes.push(ofx_node_name);\nconsole.log(msg);"},
]

function updateNode(node: Node | undefined, elements: Elements): Elements {
  return elements.map( (e) => {
    if ( e.id === node?.id ) {
      e.data = {...node.data}
      return e;
    }
    return e;
  })
}

function Editor() {
  const [elements, setElements] = useState<Elements>([]);
  const [selected, setSelected] = useState<Node<Service>>();
  if ( selected && !elements.find( (e) => e.id === selected?.id )) {
    setSelected(undefined);
  }
  const updateElements = (node: Node | undefined) => {
    const newElements = updateNode(node, elements);
    setElements(newElements);
    setSelected(newElements.find( (e) => e.id === selected?.id) as Node<Service>);
  }
  return (
    <ReactFlowProvider>
      <Palette things={things}/>
      <Flow elements={elements} updateElements={setElements} onSelect={(service) => setSelected(service)}>
        <Controls/>
      </Flow>
      <Sidebar code={selected?.data?.code}
               onCodeChange={(text) => {
                 if (selected?.data) {
                   selected.data.code = text;
                   updateElements(selected)
                 }
               }}
               setup={selected?.data?.setup}
               onSetupChange={(text) => {
                 if (selected?.data) {
                   selected.data.setup = text;
                   updateElements(selected);
                 }
               }}
               title={selected?.data?.label}
               onTitleChange={(text) => {
                 if (selected?.data) {
                   selected.data.label = text;
                   updateElements(selected);
                 }
               }}
               output1={JSON.stringify(elements)}
               output2={genAll(elements)}
      />
    </ReactFlowProvider>
  )
}

export default Editor;
